/* It means that If browser is not able to load .woff2 file, then load .woff file, if .woff is also not loading then use .otf */
@font-face {
  font-family: 'Toboggan';
  src: url('./assets/Toboggan-Light.woff2') format('woff2'),
       url('./assets/Toboggan-Light.woff') format('woff'),  
       url('./assets/Toboggan-Light.otf') format('opentype');         
}

.react-datepicker-wrapper input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
  }
  
  /* Customize the calendar container */
  .react-datepicker {
    font-family: Arial, sans-serif;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Customize the header of the calendar */
  .react-datepicker__header {
    background-color: #bbdcff;
    color: white;
  }
  
  /* Customize the navigation buttons */
  .react-datepicker__navigation {
    color: white;
  }
  
  /* Customize the selected day */
  .react-datepicker__day--selected {
    background-color: #007bff;
    color: white;
  }
  
  /* Customize the day that is hovered over */
  .react-datepicker__day--keyboard-selected:hover {
    background-color: #0056b3;
    color: white;
  }
  



  .img2-sec2{
    transform: scaleX(-1);
  }







  .header{
    width: 1540px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    border-bottom: 2px solid #4db2e0;
    height: 88px;
    box-sizing: border-box;  /* Include padding and border */
  }

  .header-left-padding{
    padding-left: 2rem;
  }
  .header-logo-parent{
    width: 180px;
    height: 45px;
    padding: 5px;
  }






.sec1-parent{
  display: flex;
}
.sec1-parent2{
  display: none;
}
.sec1-parent3{
  display: none;
}
.sec1-parent4{
  display: none;
}
.sec1-arrows{
  position: absolute;
  transform: rotate(46.25deg);
  width: 10px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.sec1-arrows2{
  position: absolute;
  transform: rotate(46.25deg);
  width: 0px;
  height: 30px;
  display: none;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}
.sec1-arrows3{
  position: absolute;
  transform: rotate(46.25deg);
  width: 0px;
  height: 15px;
  display: none;
  justify-content: center;
  align-items: center;
  gap: 1.1rem;
}
.sec1-left{
    width: 45%;
    height: 100%;
    /* padding-left: 2rem; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.sec1-p{
  margin-left: 16px;
}
.sec1-right{
    width: 55%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sec1-left-container{
  display: flex;
  flex-direction: column;
  font-size: 34px;
  color: white;
  line-height: 1.5;
}  
.sec1-right-container{
  display: flex;
  flex-direction: column;
  font-size: 26px;
  margin-left: 0.5rem;
  color: white;
}
.sec1-right-cont{
  display: flex;
  flex-direction: column;
  gap: 3rem;
  color: #242124; 
  overflow: hidden;      /*  Because of overflow:"hidden", the animation is not going out of screen, otherwise everytime animation starts screen renders additional width  */
}
.logo-width{
  width: 166px;
}

.header-links{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.6rem;
  font-size: 19px;
}

.tl-logo-sec1{
  width: 140px;
  height: 35px;
  margin-left: 1rem;
}

.sec1-px{
  display: flex;
  flex-direction: column;
  font-size: 19px;
  margin-left: 0.5rem;
  color: white;
}

@media(min-width:1200px) and (max-width:1300px){
  .header-left-padding{
    padding-left: 1.5rem;
  }
  .sec1-arrows{
    display: none;
  }
  .sec1-arrows2{
    display: flex;
  }
  .sec1-left-container{
    font-size: 30px;
  }
  .sec1-right-container{
    font-size: 23px;
  }

  .sec1-right-cont{
    gap: 2.8rem;   
  }
  .logo-width{
    width: 156px;
  }
  .sec1-parent{
    display: none;
  }
  .sec1-parent3{
    display: flex;
  }
  .header{
    height: 78px;
  }
  .header-logo-parent{
    width: 160px;
    height: 40px;
    padding: 5px;
  }
  .header-links{
    font-size: 17px;
    gap: 2.1rem;
  }
  .tl-logo-sec1{
    width: 120px;
    height: 30px;
    margin-left: 1rem;
  }
}
@media(min-width:1100px) and (max-width:1200px){
  .header-left-padding{
    padding-left: 1.5rem;
  }
  .sec1-arrows{
    display: none;
  }
  .sec1-arrows3{
    display: flex;
  }
  .sec1-left-container{
    font-size: 29px;
  }
  .sec1-right-container{
    font-size: 23px;
  }

  .sec1-right-cont{
    gap: 2.5rem;   
  }
  .logo-width{
    width: 146px;
  }
  .sec1-p{
    margin-left: 14px;
  }
  .sec1-parent{
    display: none;
  }
  .sec1-parent2{
    display: flex;
  }
  .header{
    height: 68px;
  }
  .header-logo-parent{
    width: 160px;
    height: 40px;
    padding: 5px;
  }
  .tl-logo-sec1{
    width: 120px;
    height: 30px;
    margin-left: 1rem;
  }
  .header-links{
    font-size: 15.5px;
    gap: 1.45rem;
  }
}
@media(min-width:900px) and (max-width:1100px){
  .header-left-padding{
    padding-left: 1rem;
  }
  .sec1-arrows{
    display: none;
  }
  .sec1-arrows3{
    display: flex;
  }
  .sec1-left-container{
    font-size: 24px;
  }
  .sec1-right-container{
    font-size: 20px;
  }

  .sec1-right-cont{
    gap: 2.2rem;   
  }
  .logo-width{
    width: 128px;
  }
  .sec1-p{
    margin-left: 12px;
  }
  .sec1-parent{
    display: none;
  }
  .sec1-parent2{
    display: flex;
  }
  .header{
    height: 68px;
  }
  .header-logo-parent{
    width: 130px;
    height: 32.5px;
    padding: 5px;
  }
  .header-links{
    font-size: 15px;
    gap: 1.5rem;
  }
  .tl-logo-sec1{
    width: 105px;
    height: 26.25px;
    margin-left: 0.75rem;
  }
}
@media(min-width:380px) and (max-width:900px){
  .header{
    height: 65px;
    padding-left: 1rem;
  }
/*   .header-links{
    display: none;
  } */
    .header-links{
    font-size: 15px;
    gap: 1.5rem;
  }
  .header-logo-parent{
    width: 120px;
    height: 30px;
    padding: 5px;
  }
  .header-left-padding{
    padding-left: 0.5rem;
  }

  .sec1-parent{
    display: none;
  }
  .sec1-parent4{
    display: flex;
  }
}
@media(min-width:334px) and (max-width:380px){
  .header{
    height: 65px;
    padding-left: 1rem;
  }
/*   .header-links{
    display: none;
  } */
    .header-links{
    font-size: 15px;
    gap: 1.5rem;
  }
  .header-logo-parent{
    width: 120px;
    height: 30px;
    padding: 5px;
  }
  .header-left-padding{
    padding-left: 0.5rem;
  }
  .sec1-px{
    font-size: 18px;
  }

  .sec1-parent{
    display: none;
  }
  .sec1-parent4{
    display: flex;
  }
}









.sec2-parent{
  display: flex;
}
.sec2-parent2{
  display: none;
}
.sec2-parent3{
  display: none;
}
.sec2-parent4{
  display: none;
}
.sec2-left{
  width: 40%;
  height: 100%;
  /* padding-left: 2rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.sec2-right{
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sec2-left-container{
  display: flex;
  flex-direction: column;
  font-size: 32px;
  color: black;
  line-height: 1.5;
}
.logo-width2{
  width: 158px;
}
.sec2-right-cont{
  display: flex;
  flex-direction: column;
  gap: 3.3rem;
  color: black;
  overflow: hidden;
}
.sec2-right-container{
  display: flex;
  font-size: 25px;
  color: rgb(31,31,31);
}
.tl-logo-sec2{
  width: 140px;
  height: 35px;
  margin-left: 0.7rem;
}

.sec2-px{
  display: flex;
  flex-direction: column;
  font-size: 17.5px;
  margin-left: 0.5rem;
  color: black;
}
@media(min-width:1200px) and (max-width:1300px){
  .sec2-left-container{
    font-size: 29px;
  }
  .logo-width2{
    width: 145px;
  }
  .sec2-right-cont{
    gap: 3rem;
  }
  .sec2-right-container{
    font-size: 23px;
  }
  .tl-logo-sec2{
    width: 125px;
    height: 31.25px;
    margin-left: 0.8rem;
  }
  .sec2-parent{
    display: none;
  }
  .sec2-parent2{
    display: flex;
  }
}
@media(min-width:1100px) and (max-width:1200px){
  .sec2-left-container{
    font-size: 27.2px;
  }
  .logo-width2{
    width: 120px;
  }
  .sec2-right-cont{
    gap: 2.4rem;
  }
  .sec2-right-container{
    font-size: 22px;
  }
  .tl-logo-sec2{
    width: 125px;
    height: 31.25px;
    margin-left: 0.8rem;
  }
}
@media(min-width:900px) and (max-width:1100px){
  .sec2-left-container{
    font-size: 25px;
  }
  .logo-width2{
    width: 120px;
  }
  .sec2-right-cont{
    gap: 2.4rem;
  }
  .sec2-right-container{
    font-size: 20px;
  }
  .tl-logo-sec2{
    width: 105px;
    height: 26.25px;
    margin-left: 0.8rem;
  }
  .sec2-parent{
    display: none;
  }
  .sec2-parent3{
    display: flex;
  }
}
@media(min-width:380px) and (max-width:900px){
  .sec2-parent{
    display: none;
  }
  .sec2-parent4{
    display: flex;
  }
}
@media(min-width:334px) and (max-width:380px){
  .sec2-parent{
    display: none;
  }
  .sec2-parent4{
    display: flex;
  }
  .sec2-px{
    font-size: 15.5px;
  }
}









.tl-logo-sec3{
  width: 130px;
  height: 32.5px;
  margin-left: 0.7rem;
}
.tl-logo-sec3-i{
  width: 85px;
  height: 21.25px;
  margin-left: 0.5rem;
}
.sec3-parent{
  display: flex;
}
.sec3-parent2{
  display: none;
}
.sec3-parent3{
  display: none;
}
.sec3-parent4{
  display: none;
}
.sec3-left{
  width: 40%;
  height: 100%;
  padding-left: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sec3-right{
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sec3-left-container{
  display: flex;
  flex-direction: column;
  font-size: 40px;
  color: black;
  line-height: 1.2;
}
.logo-width3{
  width: 160px;
}
.sec3-right-cont{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: #242124;
  overflow: hidden;
}
.sec3-right-container{
  display: flex;
  flex-direction: column;
  font-size: 23px;
  margin-left: 0.5rem;
}
.logo-width4{
  width: 102px;
}
.sec3-px{
  display: flex;
  flex-direction: column;
  font-size: 15.4px;
}
@media(min-width:1200px) and (max-width:1300px){
  .sec3-left-container{
    font-size: 34px;
  }
  .logo-width3{
    width: 132px;
  }
  .sec3-right-cont{
    gap: 1.9rem;
  }
  .sec3-right-container{
    font-size: 20.5px;
  }
  .logo-width4{
    width: 98px;
  }
  .sec3-parent{
    display: none;
  }
  .sec3-parent2{
    display: flex;
  }
  .tl-logo-sec3{
    width: 110px;
    height: 27.5px;
    margin-left: 0.7rem;
  }
  .tl-logo-sec3-i{
    width: 80px;
    height: 20px;
    margin-left: 0.5rem;
  }
}
@media(min-width:1100px) and (max-width:1200px){
  .sec3-left-container{
    font-size: 31px;
  }
  .logo-width3{
    width: 120px;
  }
  .sec3-right-cont{
    gap: 1.8rem;
  }
  .sec3-right-container{
    font-size: 20.5px;
  }
  .logo-width4{
    width: 96px;
  }
  .tl-logo-sec3{
    width: 110px;
    height: 27.5px;
    margin-left: 0.7rem;
  }
  .tl-logo-sec3-i{
    width: 80px;
    height: 20px;
    margin-left: 0.5rem;
  }
}
@media(min-width:900px) and (max-width:1100px){
  .sec3-left-container{
    font-size: 30px;
  }
  .logo-width3{
    width: 118px;
  }
  .sec3-right-cont{
    gap: 1.8rem;
  }
  .sec3-right-container{
    font-size: 18px;
  }
  .logo-width4{
    width: 92px;
  }
  .sec3-parent{
    display: none;
  }
  .sec3-parent3{
    display: flex;
  }
  .tl-logo-sec3{
    width: 100px;
    height: 25px;
    margin-left: 0.7rem;
  }
  .tl-logo-sec3-i{
    width: 70px;
    height: 17.5px;
    margin-left: 0.5rem;
  }
}
@media(min-width:380px) and (max-width:900px){
  .sec3-parent{
    display: none;
  }
  .sec3-parent4{
    display: flex;
  }
}
@media(min-width:334px) and (max-width:380px){
  .sec3-parent{
    display: none;
  }
  .sec3-parent4{
    display: flex;
  }
  .sec3-px{
    font-size: 13.8px;
  }
}








.tl-logo-m{
  width: 90px;
  height: 24.5px;
}

.tl-logo-m2{
  width: 85px;
  height: 23.25px;
}

.tl-logo-m3{
  width: 80px;
  height: 22px;
  margin-left: 0.8rem;
}
.tl-logo-m4{
  width: 60px;
  height: 16px;
}











.para1-animation {
  animation-name: floating1;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-delay: 0.1s;
  animation-fill-mode: forwards; /* Optional, to keep the final state */
  opacity: 0;
}


@keyframes floating1{                      
  0%   {      
      transform: translate(80rem, 0);     
      opacity: 0;
  }
  100%   {      
      transform: translate(0, 0);    
      opacity: 1;
  }
}



.para2-animation{
  animation: floating2 0.5s linear 0.9s forwards;
  opacity: 0;
}


@keyframes floating2{                      
  0%   {      
      transform: translate(80rem, 0);     
      opacity: 0;
  }
  100%   {      
      transform: translate(0, 0);    
      opacity: 1;
  }
}



.para3-animation{
  animation: floating3 0.5s linear 1.7s forwards;
  opacity: 0;
}


@keyframes floating3{                      
  0%   {      
      transform: translate(80rem, 0);     
      opacity: 0;
  }
  100%   {      
      transform: translate(0, 0);    
      opacity: 1;
  }
}

















.PPforLaptops{
  background-color: #f1f1f1;
  width: 1536px;
  letter-spacing: 0.5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem;
  gap: 4rem;
}

.PPforMobiles{
  display: none;
  background-color: #f1f1f1;
  width: 1536px;
  letter-spacing: 0.5px;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  gap: 4rem;
}

@media(min-width:334px) and (max-width:980px){
  .PPforMobiles{
    display: flex;
  }
  .PPforLaptops{
    display: none;
  }
}





.TOSforLaptops{
  background-color: #f1f1f1;
  width: 1536px;
  letter-spacing: 0.5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem;
  gap: 4rem;
}

.TOSforMobiles{
  display: none;
  background-color: #f1f1f1;
  width: 1536px;
  letter-spacing: 0.5px;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  gap: 4rem;
}

@media(min-width:334px) and (max-width:980px){
  .TOSforMobiles{
    display: flex;
  }
  .TOSforLaptops{
    display: none;
  }
}
