.top-gradient{
    /* background-image: linear-gradient(180deg, #f4f8ff, #42b0ff99); */
    background-color: #0D99FF99;
    display: flex;
    justify-content: center;

    width: 100%;
    height: 587px;

    margin-left: auto;     /*  These 2 commands are equivalent to mx-auto in tailwind */
    margin-right: auto; 
}

/* .wrapper-loginpage{
    margin-top: 150px;    Because I want my navbar to be fixed on top, when I gave fixed positioning to navbar, I have to give top-margin(equal to height occupied by navbar) to Login-Signup page
} */

.login-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 91.666667%;
    max-width: 1160px;

    margin-left: auto;     /*  These 2 commands are equivalent to mx-auto in tailwind */
    margin-right: auto;
    scale: 90%;
}

.login-page-left-section{
    border: 1px solid #D3D3D3;
    border-radius: 5%;
    padding: 3rem 3.5rem 3.5rem 3.5rem;
    background: white;
}

.heading-login{
    font-size: 2.3rem;
}
.para-login{
    margin-top: 24px;
}
.form-login{
    margin-top: 24px;
}
.login-or{
    margin-top: 16px;
    margin-bottom: 16px;
}

.desc-color{
    color: #0D99FF;
}

.label-font{
    font-weight: 500;
}

.star{
    color: red;
}

.emailIcon{
    position: absolute;
    bottom: 11px;
    left: 16px;

    color: rgb(170, 170, 170);
    font-weight: bold;
}

.input-border{
    border: gray 1px solid;
}

.passwordIcon{
    position: absolute;
    bottom: 39.5px;
    left: 14px;

    color: rgb(170, 170, 170);
    font-weight: bold;
}


input:focus {
    border: 2px solid #0D99FF;   /* Set border style, width, and color */
    outline: none;                 /* Remove default outline */
}

#zx1{
    width: 15px;
}

.RememberMe{
    color: #000000;
    font-size: 13px;
    font-weight: 600;
    margin-left: 0.3rem;
    padding-bottom: 0.1rem;
}

.forgot-password{
    color: #0D99FF;
    font-size: small;
    font-weight: 600;
}

.custom-btn{
    background: #4db2e0;
    font-size: 1.2rem;
    font-weight: 600;
    color: white;
    margin-top: 40px;
}

.dont-have-account{
    font-size: 1rem;
    color: rgb(74, 74, 74);
    font-weight: 600;
}

.dont-have-account-signup{
    font-size: 1rem;
    color: #0366ad;
    font-weight: bold;
}

.Signup-with-Google{
    background: white;
    font-size: 1.1em;
    font-weight: 600;
}

.imgShadow{
    box-shadow: -14px -13px 18px #0D99FF99;
}





@media(min-width:950px) and (max-width:1050px){

    .login-wrapper{
        justify-content: center;
        gap: 3rem;
    }
}
@media(min-width:472px) and (max-width:950px){
    .img-girl-login{
        display: none;
    }
    .login-wrapper{
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
    }
}
@media(min-width:400px) and (max-width:472px){
    .img-girl-login{
        display: none;
    }
    .login-wrapper{
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
    }
    .para-login{
        margin-top: 14px;
    }
    .form-login{
        margin-top: 18px;
    }
    .custom-btn{
        margin-top: 36px;
    }
    .login-or{
        margin-top: 13px;
        margin-bottom: 13px;
    }
}












.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    translate: -50% -50%;            /* Because of positioning of modal as 50% from top and 50% from left, our modal's left edge will be in the center and modal's upper edge will be in the center, resulting in modal to be in present in bottom-right part of screen 
                                        To bring our modal in center of screen we will use translate: -50% -50%;  -  translate is basically margin, - means negative marging
                                        What it does is the our modal will move up by 50% of modal's height   &   our modal will move left by 50% of modal's width (if width of modal is 400px, then modal will move to left by 200px, if height of modal is 200px, then modal will move to up by 100px) */
    border: 2px solid #ffffff59;
    width: 600px;
    scale: 0;                      /* Scale:0  means hidden, when user will click on button "Share My Profile", then scale will be set to 1(means visible) */
    background: #00000088;       /* Transluscent background for modal */
    backdrop-filter: blur(10px);   /* Blur on Modal */
    z-index: 1;                    /* So that our modal can stay on top of overlay ()   -   Overlay is : Total screen - Modal area    (overlay is layer of blackish sheet on rest of the screen, so that modal looks so attractive) */
    padding: 20px 25px 20px 25px;
    border-radius: 20px;
    transition: 0.5s ease-in-out;
}

.overlay {
    position: fixed;
    background-color: #4f4f4f83;   /* Color of overlay(which is transluscent)   #5f5f5f83;   */
    backdrop-filter: blur(5px);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;      /* Here top,bottom,left,right=0;  means that entire screen is covered by it */
  
    opacity: 0;
    pointer-events: none;          /* Without this property I am not able to click on any button */
    transition: 0.5s ease-in-out;
}
.active {              /* To understand about it : Refer to its .js file */
    scale: 1;
}
.overlayactive {       /* To understand about it : Refer to its .js file */
    opacity: 1;
    pointer-events: initial;       /* When modal is open and I click on overlay, then modal gets closed. */
}                                /* However without this property nothing happens when we click on overlay, the modal doest not closes */


.modal-top{
    padding-bottom: 1.8rem;
    border-bottom: 1px solid #ffffff88;
}

.already-have-account{
    font-size: 1rem;
    color: white;
    font-weight: 600;
}
.already-have-account-signin{
    font-size: 1rem;
    color: #4bb3fe;
    font-weight: bold;
}

.account-type{
    background: #bebebe;   
}

.account-type-selected{
    background: #ffffff;
    color: #000000;
    font-size: 19px;
}

.account-type-unselected{
    background: #c4c4c400;
    color: #313131;
    font-size: 19px;
}

.account-type-unselected:hover{
    color: black;
}


.width1{
    width: 47%;
}
.width2{
    width: 77%;
}
.width3{
    width: 17%;
}


.UserIcon{
    position: absolute;
    bottom: 12px;
    left: 16px;

    color: rgb(170, 170, 170);
    font-weight: bold;
}

.emailIcon2{
    position: absolute;
    bottom: -2px;
    left: 14px;

    color: rgb(170, 170, 170);
    font-weight: bold;
}

.PhoneIcon{
    position: absolute;
    bottom: 11px;
    left: 132px;

    color: rgb(170, 170, 170);
    font-weight: bold;
}

.passwordIcon2{
    position: absolute;
    bottom: 10px;
    left: 14px;

    color: rgb(170, 170, 170);
    font-weight: bold;
}